



























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import api from "@/api/api";
import { mapGetters } from "vuex";
import {
  ParkingStatus,
  ParkingSpotUpdate,
  ParkingSpot,
  ParkingLot,
  ParkingZone,
  ParkingLane,
  ParkingPermit,
  SpecialArea,
  SpecialAreaType,
  ParkingSpotSavedEndUserDetails,
  ParkingLocationSavedEndUserDetails,
} from "@/api/models";
import ViewStreamHelpPopup from "@/components/ViewStreamHelpPopup.vue";
import ReportSpotInaccuracyForm from "@/components/forms/ReportSpotInaccuracyForm.vue";
import VehicleParkingUsageRecordDetails from "@/components/VehicleParkingUsageRecordDetails.vue";

type VForm = Vue & { resetValidation: () => boolean; validate: () => boolean };

export default Vue.extend({
  name: "PropertiesPopup",

  components: {
    ViewStreamHelpPopup,
    ReportSpotInaccuracyForm,
    VehicleParkingUsageRecordDetails,
  },

  props: {
    category: {
      type: String,
      required: true,
    },
    showPopup: {
      type: Boolean,
      required: true,
    },
    parkingLotData: {
      type: Object as () => ParkingLot | null,
      required: true,
    },
    cameraData: {
      type: Object,
    },
    spotData: {
      type: Object as () => ParkingSpot,
    },
    showAnprFields: {
      type: Boolean,
      default: false,
    },
    zoneData: {
      type: Object as () => ParkingZone,
    },
    specialAreaData: {
      type: Object as () => SpecialArea,
    },
    laneData: {
      type: Object as () => ParkingLane,
    },
    annotationObj: {
      type: Object,
    },
    availableParkingPermitsList: {
      type: Array as () => Array<ParkingPermit> | null,
    },
    savedSpot: {
      type: Object as () => ParkingSpotSavedEndUserDetails | null,
    },
    savedLocation: {
      type: Object as () => ParkingLocationSavedEndUserDetails | null,
    },
    isEditMode: {
      type: Boolean,
      default: true,
    },
    showSpotNames: {
      type: Boolean,
      default: true,
    },
    spotNames: {
      type: Array as () => Array<string> | null,
    },
    openSpotSlider: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      id: null as number | null,
      camera: {
        allFieldsValid: false,
        name: "",
        stream_url: "",
        is_active: false,
        comment: "" as string | null,
      },
      spot: {
        allFieldsValid: false,
        name: "" as string | null,
        current_status: "",
        camera_id: null as number | null,
        max_park_violation_alert_time_seconds: null as number | null,
        is_handicap_parking: false,
        is_status_marked_unknown: false,
        is_marked_uknown_spot_updates_enabled: false,
        is_lane_based_matching_logic_enabled: false,
        is_illegal_parking_spot: false,
        comment: "" as string | null,
        requires_parking_permit_ids: null as Array<number> | null,
        vehicle_parking_usage_anpr_lp_number: null as string | null,
        vehicle_parking_usage_anpr_record_id: null as number | null,
      },
      landmark: {
        name: "",
      },
      zone: {
        zoneCategory: {
          items: [
            { value: "normal", label: "Spot Tracking Zone" },
            { value: "untracked", label: "Car Counting Zone" },
            { value: "time_limited", label: "Drop Off/Pick Up Zone" },
          ],
          selected: "normal",
        },
        name: null as string | null,
        allFieldsValid: false,
        untrackedZoneHasCameras: false,
        zoneType: {
          items: [
            { id: "untracked_zone", name: "ROI Area Based Counting (AI)" },
            {
              id: "line_counter_zone",
              name: "Vehicle Crossing Line Based Counting (AI on Edge Devices)",
            },
            {
              id: "lpr_counter_zone",
              name: "LPR Entry/Exit Detection Based Counting (AI)",
            },
            // "normal_spots_zone",  // Hidden
            // "time_limited_zone",   // Hidden
            { id: "manual_counting", name: "Manual Counting" }, // Dynamically Set
          ],
          selected: "normal_spots_zone",
        },
        is_untracked_disabled: false,
        is_visible_to_customers: true,
        is_visible_to_end_users: true,
        num_total_untracked_spots: 0,
        num_free_untracked_spots: 0,
        is_num_free_untracked_spots_automatic: false,
        vehicle_parking_usage_anpr_lp_number: null as string | null,
        vehicle_parking_usage_anpr_record_id: null as number | null,
        max_park_violation_alert_time_seconds: null as number | null,
      },
      specialArea: {
        allFieldsValid: false,
        name: null as string | null,
        area_type: SpecialAreaType.restricted_area as SpecialAreaType,
        is_illegal_parking_area: false,
        is_show_area_name_enabled: false,
        illegalParkingViolationAlertTimeSeconds: 120,
        specialAreaTypeItems: Object.values(SpecialAreaType),
        vehicle_parking_usage_anpr_lp_number: null as string | null,
        vehicle_parking_usage_anpr_record_id: null as number | null,
        current_vehicle_count_detected: 0 as number,
      },
      lane: {
        allFieldsValid: false,
        name: null as string | null,
        is_parallel_parking_lane: false,
        is_double_parking_lane_enabled: false,
        is_near_road_interference: false,
        road_interference_sampling_rate_secs: null as number | null,
      },
      spotStatuses: [
        { name: "Available", value: "free" },
        { name: "Unavailable", value: "unavailable" },
        { name: "Blocked", value: "reserved" },
      ],
      confirmDelete: {
        show: false,
        called_from: "",
        resolve: null as any,
        reject: null as any,
      },
      viewFrame: {
        show: false,
        cameraFrameUrl: "" as string,
      },
      showViewStreamHelpDialog: false,
      showVehicleParkingUsageDialog: false,
      commonComments: [
        "Blocked by Tree",
        "Blocked by Object",
        "Better Camera Angle",
      ],
      reportSpotInaccuracy: {
        show: false,
        loading: false,
      },
      markSpotBlocked: false,
      parkingTime: {
        hours: 0,
        minutes: 0,
        seconds: 0,
        enabled: false,
        show: false,
      },

      IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED:
        process.env.VUE_APP_IS_FEATURE_4761_UNTRACKED_ZONES_LPR_ALERTS_ENABLED,
    };
  },

  computed: {
    ...mapGetters("user", ["isSuperAdmin"]),
    headingTitle(): string {
      let idText = String(this.id || "");
      if (this.category == "spot" && this.showSpotNames && this.spotData.name) {
        idText = this.spotData.name;
      }
      if (this.category) {
        return (
          this.category[0].toUpperCase() + this.category.slice(1) + " " + idText
        );
      } else {
        return "";
      }
    },
    propertiesValid(): boolean {
      if (this.category === "spot") {
        return this.spot.allFieldsValid;
      }
      if (this.category === "camera") {
        return this.camera.allFieldsValid;
      }
      if (this.category === "zone" || this.category === "parking_structure") {
        return this.zone.allFieldsValid;
      }
      if (this.category === "special_area") {
        return this.specialArea.allFieldsValid;
      }
      return true;
    },
    spotStatusName() {
      const spot_status: any = this.spotStatuses.find(
        (s) => s.value == this.spot.current_status
      );
      if (spot_status) {
        return spot_status.name;
      }
      return "";
    },
    statusUnknownCauses() {
      // Unknown spot status reasons
      let statusUnknownCauses = [];
      if (this.spotData?.camera_id == null) {
        statusUnknownCauses.push("Camera not assigned, spot status unknown");
      } else if (this.spotData?.is_status_unknown_camera_inactive) {
        statusUnknownCauses.push("Camera is switched off, spot status unknown");
      } else if (this.spotData?.is_status_unknown_camera_offline) {
        statusUnknownCauses.push(
          "SpotGenius unable to determine status: Camera is offline. Please check power/network status or contact your network administrator"
        );
      } else if (this.spotData?.is_status_unknown_edge_device_offline) {
        statusUnknownCauses.push(
          "SpotGenius unable to determine status: Edge device is offline. Please check power/network status or contact your network administrator"
        );
      }
      if (this.spotData?.is_status_marked_unknown) {
        statusUnknownCauses.push("Status marked unknown by admin");
      }
      if (this.spotData?.is_status_unknown_flip_flop) {
        statusUnknownCauses.push(
          "SpotGenius unable to determine status: Spot status is rapidly changing and may be inaccurate. Please check for any obstructions to the camera's view of that spot. Alternatively, contact SpotGenius support for more information"
        );
      }
      if (this.spotData?.is_status_unknown_parallel_parking) {
        statusUnknownCauses.push(
          "Spot status is unknown due to parallel parking"
        );
      }
      return statusUnknownCauses;
    },
    anprParkingUsageRecordId(): number | null {
      if (this.category === "spot") {
        return this.spot.vehicle_parking_usage_anpr_record_id;
      }
      if (this.category === "zone" || this.category === "parking_structure") {
        return this.zone.vehicle_parking_usage_anpr_record_id;
      }
      if (this.category === "special_area") {
        return this.specialArea.vehicle_parking_usage_anpr_record_id;
      }
      return null;
    },
    maxParkTimeSeconds(): number | null {
      return Number(
        Number(this.parkingTime.hours * 3600) +
          Number(this.parkingTime.minutes * 60) +
          Number(this.parkingTime.seconds)
      );
    },
  },

  async mounted() {
    this.initData();
  },

  methods: {
    async initData() {
      switch (this.category) {
        case "camera": {
          this.id = this.cameraData.id;
          this.camera.name = this.cameraData.name;
          this.camera.stream_url = this.cameraData.stream_url;
          this.camera.is_active = this.cameraData.is_active;
          this.camera.comment = this.cameraData.comment;
          break;
        }
        case "spot": {
          this.id = this.spotData.id;
          this.spot.name = this.spotData.name;
          this.spot.current_status = this.spotData.current_status;
          this.spot.camera_id = this.spotData.camera_id;
          this.spot.max_park_violation_alert_time_seconds =
            this.spotData.max_park_violation_alert_time_seconds;
          if (
            this.spot.max_park_violation_alert_time_seconds &&
            this.spot.max_park_violation_alert_time_seconds > 0
          ) {
            this.parkingTime.show = true;
          }

          if (this.spot.max_park_violation_alert_time_seconds) {
            [
              this.parkingTime.hours,
              this.parkingTime.minutes,
              this.parkingTime.seconds,
            ] = this.getTimeFromSeconds(
              this.spot.max_park_violation_alert_time_seconds
            );
          } else {
            this.clearParkingTime();
          }

          this.spot.is_handicap_parking = this.spotData.is_handicap_parking;
          this.spot.is_status_marked_unknown =
            this.spotData.is_status_marked_unknown;
          this.spot.is_marked_uknown_spot_updates_enabled =
            this.spotData.is_marked_uknown_spot_updates_enabled;
          this.spot.is_lane_based_matching_logic_enabled =
            this.spotData.is_lane_based_matching_logic_enabled;
          this.spot.is_illegal_parking_spot =
            this.spotData.is_illegal_parking_spot;
          this.spot.comment = this.spotData.comment;
          this.spot.requires_parking_permit_ids =
            this.spotData.requires_parking_permit_ids;
          (this.spot.vehicle_parking_usage_anpr_lp_number =
            this.spotData.vehicle_parking_usage_anpr_lp_number),
            (this.spot.vehicle_parking_usage_anpr_record_id =
              this.spotData.vehicle_parking_usage_anpr_record_id),
            (this.markSpotBlocked =
              this.spot.current_status == ParkingStatus.reserved);
          break;
        }
        case "zone":
        case "parking_structure": {
          this.id = this.zoneData.id;
          this.zone.name = this.zoneData.name;
          this.zone.zoneType.selected = this.zoneData.zone_type;
          // Set manual counting if zone is untracked and automatic counting disabled
          if (
            this.zoneData.zone_type === "untracked_zone" &&
            !this.zoneData.is_num_free_untracked_spots_automatic
          ) {
            this.zone.zoneType.selected = "manual_counting";
          }
          this.zone.is_untracked_disabled = this.zoneData.is_untracked_disabled;
          this.zone.num_total_untracked_spots =
            this.zoneData.num_total_untracked_spots || 0;
          if (this.zoneData.is_num_free_untracked_spots_automatic) {
            this.zone.num_free_untracked_spots =
              this.zoneData.num_free_parking_spots || 0;
          } else {
            this.zone.num_free_untracked_spots =
              this.zoneData.num_free_untracked_spots || 0;
          }
          this.zone.is_num_free_untracked_spots_automatic =
            this.zoneData.is_num_free_untracked_spots_automatic;
          this.zone.untrackedZoneHasCameras =
            (await this.checkUntrackedZoneAssignedToCamera()) || false;
          this.zone.vehicle_parking_usage_anpr_lp_number =
            this.zoneData.vehicle_parking_usage_anpr_lp_number;
          this.zone.vehicle_parking_usage_anpr_record_id =
            this.zoneData.vehicle_parking_usage_anpr_record_id;
          this.zone.max_park_violation_alert_time_seconds =
            this.zoneData.max_park_violation_alert_time_seconds;

          this.zone.is_visible_to_customers =
            this.zoneData.is_visible_to_customers;
          this.zone.is_visible_to_end_users =
            this.zoneData.is_visible_to_end_users;

          if (this.zone.max_park_violation_alert_time_seconds) {
            [
              this.parkingTime.hours,
              this.parkingTime.minutes,
              this.parkingTime.seconds,
            ] = this.getTimeFromSeconds(
              this.zone.max_park_violation_alert_time_seconds
            );
          } else {
            this.clearParkingTime();
          }

          // Set zone category based on zone type
          if (this.zone.zoneType.selected == "normal_spots_zone") {
            this.zone.zoneCategory.selected = "normal";
          } else if (this.zone.zoneType.selected == "time_limited_zone") {
            this.zone.zoneCategory.selected = "time_limited";
          } else {
            this.zone.zoneCategory.selected = "untracked";
          }

          break;
        }
        case "special_area": {
          this.id = this.specialAreaData.id;
          this.specialArea.name = this.specialAreaData.name
            ? this.specialAreaData.name.trim()
            : null;
          this.specialArea.area_type = this.specialAreaData.area_type;
          this.specialArea.is_illegal_parking_area =
            this.specialAreaData.is_illegal_parking_area;
          this.specialArea.is_show_area_name_enabled =
            this.specialAreaData.is_show_area_name_enabled;
          this.specialArea.illegalParkingViolationAlertTimeSeconds =
            this.specialAreaData.illegal_parking_violation_alert_time_seconds;
          if (!this.specialArea.name) {
            this.specialArea.is_show_area_name_enabled = false;
          }
          this.specialArea.vehicle_parking_usage_anpr_lp_number =
            this.specialAreaData.vehicle_parking_usage_anpr_lp_number;
          this.specialArea.vehicle_parking_usage_anpr_record_id =
            this.specialAreaData.vehicle_parking_usage_anpr_record_id;
          this.specialArea.current_vehicle_count_detected =
            this.specialAreaData.current_vehicle_count_detected;
          break;
        }
        case "lane": {
          this.id = this.laneData.id;
          this.lane.name = this.laneData.name;
          this.lane.is_parallel_parking_lane =
            this.laneData.is_parallel_parking_lane;
          this.lane.is_double_parking_lane_enabled =
            this.laneData.is_double_parking_lane_enabled;
          this.lane.is_near_road_interference =
            this.laneData.is_near_road_interference;
          this.lane.road_interference_sampling_rate_secs =
            this.laneData.road_interference_sampling_rate_secs;
          break;
        }
      }
    },
    changedIsLaneNearRoad(isLaneNearRoad: boolean) {
      if (isLaneNearRoad) {
        this.lane.road_interference_sampling_rate_secs = 10;
      } else {
        this.lane.road_interference_sampling_rate_secs = null;
      }
    },
    openStream() {
      navigator.clipboard.writeText(this.camera.stream_url);
      if (this.camera.stream_url.startsWith("http")) {
        // Only open the youtube streams automatically in a new tab.
        window.open(this.camera.stream_url, "_blank");
      } else {
        // Automatic opening of stream is disabled for rtsp, since it doesn't work
        // reliably on all browsers & systems. So we ask the user to launch VLC
        // themselves from the help popup dialog.
        // window.open(this.camera.stream_url);
        this.showViewStreamHelpDialog = true;
      }
    },
    showViewStreamHelp(value: boolean) {
      this.showViewStreamHelpDialog = value;
    },
    showCameraMapEditor() {
      this.closePopup();
      this.$emit("show-camera-map-editor");
    },
    showLprAlertsPage() {
      const routeData = this.$router.resolve({
        name: "LotLprManagement",
        query: {
          tab: "lpr_blacklist_whitelist",
          parking_zone_id: String(this.zoneData.id),
        },
      });
      window.open(routeData.href, "_blank");
    },
    closePopup(openSpotSlider = true) {
      this.$emit("show-loader", 3, this.id);
      this.$emit("update:showPopup", false);
      if (this.openSpotSlider && openSpotSlider) {
        this.$emit(
          "show-spot-slider",
          this.openSpotSlider,
          this.cameraData.camera_id
        );
      }
      this.closeReportInaccuracyForm();
    },
    async saveDetails() {
      switch (this.category) {
        case "camera": {
          let updatedCamera = {
            ...this.cameraData,
            name: this.camera.name,
            stream_url: this.camera.stream_url,
            is_active: this.camera.is_active,
            camera_offline_alert_delay_threshold_minutes:
              this.cameraData.camera_offline_alert_delay_threshold_minutes,
            comment: this.camera.comment,
          };
          updatedCamera = await api.updateCamera(updatedCamera);
          this.$emit("update:cameraData", updatedCamera);
          break;
        }
        case "spot": {
          let updatedSpot = {
            id: this.spotData.id,
            name: this.spot.name,
            current_status: this.spot.current_status,
            max_park_violation_alert_time_seconds:
              this.parkingTime.show && this.maxParkTimeSeconds
                ? Number(this.maxParkTimeSeconds)
                : null,
            is_handicap_parking: this.spot.is_handicap_parking,
            is_status_marked_unknown: this.spot.is_status_marked_unknown,
            is_marked_uknown_spot_updates_enabled:
              this.spot.is_marked_uknown_spot_updates_enabled,
            is_lane_based_matching_logic_enabled:
              this.spot.is_lane_based_matching_logic_enabled,
            is_illegal_parking_spot: this.spot.is_illegal_parking_spot,
            comment: this.spot.comment,
            requires_parking_permit_ids: this.spot.requires_parking_permit_ids,
            parking_lot_id: this.spotData.parking_lot_id,
          } as ParkingSpotUpdate;
          let updatedSpotResponse = await api.updateParkingSpot(updatedSpot);
          if (updatedSpotResponse) {
            this.$emit("update:spotData", updatedSpot);
          }
          break;
        }
        case "landmark": {
          if (this.annotationObj) {
            this.annotationObj.setLabel(this.landmark.name);
            this.annotationObj.name = this.landmark.name;
            this.closePopup();
            return;
          }
          break;
        }
        case "zone":
        case "parking_structure": {
          let zoneUpdate = {
            id: this.zoneData.id,
            name: this.zone.name ? this.zone.name.trim() : this.zone.name,
            is_untracked_disabled: this.zone.is_untracked_disabled,
            num_total_untracked_spots: this.zone.num_total_untracked_spots,
            num_free_untracked_spots: this.zone.num_free_untracked_spots,
            is_num_free_untracked_spots_automatic:
              this.zone.is_num_free_untracked_spots_automatic,
            // when manual count is enabled, store zone type as untracked as well
            zone_type:
              this.zone.zoneType.selected === "manual_counting"
                ? "untracked_zone"
                : this.zone.zoneType.selected,
            parking_lot_id: this.zoneData.parking_lot_id,
            entrypoints: this.zoneData.entrypoints,
            max_park_violation_alert_time_seconds: this.maxParkTimeSeconds
              ? Number(this.maxParkTimeSeconds)
              : null,

            is_visible_to_customers: this.zone.is_visible_to_customers,
            is_visible_to_end_users: this.zone.is_visible_to_end_users,
          };
          if (zoneUpdate.zone_type === "nomal_spots_zone") {
            zoneUpdate.num_total_untracked_spots = 0;
            zoneUpdate.num_free_untracked_spots = 0;
          }
          console.log("saving zone data", zoneUpdate);
          let updatedZoneResponse = await api.updateParkingZone(zoneUpdate);
          if (updatedZoneResponse) {
            this.$emit("update:zoneData", zoneUpdate);
          }
          break;
        }
        case "special_area": {
          let specialAreaUpdate = {
            id: this.specialAreaData.id,
            name: this.specialArea.name ? this.specialArea.name.trim() : null,
            is_illegal_parking_area: this.specialArea.is_illegal_parking_area,
            is_show_area_name_enabled:
              this.specialArea.is_show_area_name_enabled,
            area_type: this.specialArea.area_type,
            parking_lot_id: this.zoneData.parking_lot_id,
            illegal_parking_violation_alert_time_seconds:
              this.specialArea.illegalParkingViolationAlertTimeSeconds,
          };
          let updatedSpecialArea = await api.updateSpecialArea(
            specialAreaUpdate
          );
          if (updatedSpecialArea) {
            this.$emit("update:specialAreaData", specialAreaUpdate);
          }
          break;
        }
        case "lane": {
          let laneUpdate = {
            id: this.laneData.id,
            name: this.lane.name,
            is_parallel_parking_lane: this.lane.is_parallel_parking_lane,
            is_double_parking_lane_enabled:
              this.lane.is_double_parking_lane_enabled,
            is_near_road_interference: this.lane.is_near_road_interference,
            road_interference_sampling_rate_secs:
              this.lane.road_interference_sampling_rate_secs,
            parking_lot_id: this.zoneData.parking_lot_id,
          };
          let updatedLaneRsponse = await api.updateParkingLane(laneUpdate);
          if (updatedLaneRsponse) {
            this.$emit("update:zoneData", laneUpdate);
          }
          break;
        }
      }
      this.closePopup(false);
      this.$emit("reload-map");
    },
    showConfirmDelete(called_from: string) {
      this.$emit("update:showPopup", false);
      this.confirmDelete.show = true;
      this.confirmDelete.called_from = called_from;
      return new Promise((resolve, reject) => {
        this.confirmDelete.resolve = resolve;
        this.confirmDelete.reject = reject;
      });
    },
    cancelDelete() {
      this.$emit("update:showPopup", true);
      this.confirmDelete.show = false;
      this.confirmDelete.resolve(false);
    },
    callDelete() {
      if (this.confirmDelete.called_from === "spot") {
        this.$emit("delete-spot", this.annotationObj);
      } else if (this.confirmDelete.called_from === "landmark") {
        this.$emit("delete-landmark", this.annotationObj);
      } else if (this.confirmDelete.called_from === "zone") {
        this.$emit("delete-zone", this.annotationObj);
      } else if (this.confirmDelete.called_from === "level") {
        this.deleteMultiLevelParkingStructureLevel(this.annotationObj.id);
      } else if (this.confirmDelete.called_from === "parking_structure") {
        this.$emit("delete-zone", this.annotationObj);
      } else if (this.confirmDelete.called_from === "special_area") {
        this.$emit("delete-special-area", this.annotationObj);
      } else if (this.confirmDelete.called_from === "lane") {
        this.$emit("delete-lane", this.annotationObj);
      } else if (
        ["lane", "lot", "driveway"].includes(this.confirmDelete.called_from)
      ) {
        this.confirmDelete.resolve(true);
      }
      this.confirmDelete.show = false;
    },

    async deleteMultiLevelParkingStructureLevel(levelZoneId: number) {
      if (this.parkingLotData == null) {
        console.error("Parking Lot data not loaded in PropertiesPopup");
        return;
      }
      let deleteSuccess = await api.deleteMultiLevelParkingStructureLevel(
        this.parkingLotData.id,
        levelZoneId
      );
      if (deleteSuccess) {
        this.$router.go(0); // refresh after delete
      } else {
        this.$dialog.message.error("Unable to delete this Level", {
          position: "top-right",
          timeout: 3000,
        });
      }
    },

    /**
     * Reset untracked zone validation if the zone is not an untracked zone or
     * counter zone.
     */
    onZoneTypeChanged() {
      if (this.zone.zoneType.selected === "normal_spots_zone") {
        this.zone.num_total_untracked_spots = 0;
        this.zone.num_free_untracked_spots = 0;
        (this.$refs.zonepropertiesform as VForm).resetValidation();
      } else {
        if (this.zone.zoneType.selected === "untracked_zone") {
          this.zone.is_num_free_untracked_spots_automatic = true;
          // Show a popup warning saying that ROI needs to be drawn to use this feature.
          this.$dialog.message.info(
            "Please ensure that ROIs are drawn on the camera map of cameras assigned to this zone.",
            {
              position: "top-right",
              timeout: 6000,
            }
          );
        } else if (this.zone.zoneType.selected === "line_counter_zone") {
          this.zone.is_num_free_untracked_spots_automatic = true;
          // Show a popup warning saying that Counter line needs to be drawn to use this feature.
          this.$dialog.message.info(
            "Please ensure that Vehicle Count Lines are drawn on the camera map to use Line Counter feature.",
            {
              position: "top-right",
              timeout: 6000,
            }
          );
        } else if (this.zone.zoneType.selected === "lpr_counter_zone") {
          this.zone.is_num_free_untracked_spots_automatic = true;
          // Show a popup warning saying that LPR cameras are configured
          this.$dialog.message.info(
            "Please ensure that LPR cameras are configured on this Parking Lot.",
            {
              position: "top-right",
              timeout: 6000,
            }
          );
        } else if (this.zone.zoneType.selected === "manual_counting") {
          // Set an untracked zone with automatic counting disabled
          this.zone.is_num_free_untracked_spots_automatic = false;
        }

        (this.$refs.zonepropertiesform as VForm).validate();
      }
    },
    async checkUntrackedZoneAssignedToCamera() {
      if (this.zoneData) {
        let assignedCameras = await api.getAllCamerasAssignedToUntrackedZone(
          this.zoneData.parking_lot_id,
          this.zoneData.id
        );
        return assignedCameras && assignedCameras.length > 0;
      }
      return false;
    },

    onZoneCategoryChanged(changedTo: string) {
      console.log("Zone category changed to", changedTo);
      switch (changedTo) {
        case "normal": {
          this.zone.zoneType.selected = "normal_spots_zone";
          this.zone.is_untracked_disabled = false;
          this.zone.is_num_free_untracked_spots_automatic = false;
          break;
        }
        case "untracked": {
          if (
            !(
              this.zone.zoneType.selected === "untracked_zone" ||
              this.zone.zoneType.selected === "line_counter_zone" ||
              this.zone.zoneType.selected === "lpr_counter_zone"
            )
          ) {
            this.zone.zoneType.selected = "untracked_zone"; // Set to ROI based counting by default
          }
          this.zone.is_untracked_disabled = false;
          this.zone.is_num_free_untracked_spots_automatic = true;
          break;
        }
        case "time_limited": {
          this.zone.zoneType.selected = "time_limited_zone";
          this.zone.is_num_free_untracked_spots_automatic = true;
          break;
        }
      }
    },

    onUntrackedZoneAutoCountToggleChanged(isEnabled: boolean) {
      this.zone.num_free_untracked_spots = 0;
      this.onToggleVehicleRoiCountFeature(isEnabled);
    },

    /**
     * When enabling any feature that requires vehicle count, show a popup saying that
     * ROI needs to be drawn on the cameras.
     */
    onToggleVehicleRoiCountFeature(isEnabled: boolean) {
      if (isEnabled) {
        this.$dialog.message.info(
          "Please ensure that ROI polygon is drawn on the camera maps to use this feature.",
          {
            position: "top-right",
            timeout: 6000,
          }
        );
      }
    },

    show(url: string) {
      this.$viewerApi({
        options: {
          focus: false,
          button: false,
        },
        images: [url],
      });
    },
    async showCameraFrameDialog() {
      this.viewFrame.show = !this.viewFrame.show;
      this.$emit("show-loader", 0);
      if (
        this.cameraData &&
        this.cameraData.parking_lot_id &&
        this.cameraData.id &&
        this.cameraData.name
      ) {
        try {
          let url = await api.downloadFrame(
            this.cameraData.parking_lot_id,
            this.cameraData.id
          );
          if (url) {
            if (this.viewFrame.show) {
              this.show(url);
            }
          } else {
            this.$dialog.message.error(
              `Read Error, unable to read from camera "${this.cameraData.name}"`,
              {
                position: "top-right",
                timeout: 3000,
              }
            );
            this.viewFrame.show = false;
          }
        } catch (error) {
          if (error.response.status === 403) {
            this.$dialog.message.warning(
              "Unstable camera stream connection, unable to read frame.<br> Please try again later.",
              {
                position: "top-right",
                timeout: 5000,
              }
            );
            this.viewFrame.show = false;
          }
        }
      }
      if (this.viewFrame.show) {
        this.$emit("show-loader", 1);
        this.viewFrame.show = false;
      }
    },
    openReportInaccuracyForm() {
      this.reportSpotInaccuracy.show = true;
      this.reportSpotInaccuracy.loading = true;
    },
    hideReportInaccuracyForm() {
      this.reportSpotInaccuracy.show = false;
    },
    closeReportInaccuracyForm() {
      this.reportSpotInaccuracy.show = false;
      this.reportSpotInaccuracy.loading = false;
    },
    showAnprParkingRecordDetails(recordId: number) {
      console.log("Showing details of ANPR record ID", recordId);
      this.showVehicleParkingUsageDialog = true;
    },
    getTimeFromSeconds(seconds: number) {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      let remainingSeconds = seconds % 60;

      return [hours, minutes, remainingSeconds];
    },

    changedParkingTime(unit: string) {
      if (unit === "hours" && this.parkingTime.hours > 23) {
        this.parkingTime.hours = 23;
      }
      if (unit === "hours" && String(this.parkingTime.hours) == "") {
        this.parkingTime.hours = 0;
      }
      if (unit === "minutes" && this.parkingTime.minutes > 59) {
        this.parkingTime.minutes = 59;
      }
      if (unit === "minutes" && String(this.parkingTime.minutes) == "") {
        this.parkingTime.minutes = 0;
      }
      if (unit === "seconds" && this.parkingTime.seconds > 59) {
        this.parkingTime.seconds = 59;
      }
      if (unit === "seconds" && String(this.parkingTime.seconds) == "") {
        this.parkingTime.seconds = 0;
      }
    },

    clearParkingTime() {
      this.parkingTime.hours = 0;
      this.parkingTime.minutes = 0;
      this.parkingTime.seconds = 0;
      this.parkingTime.show = false;
    },
  },

  watch: {
    showPopup(isVisible) {
      if (isVisible) {
        this.initData();
      }
    },
    "viewFrame.show"(showingFrame) {
      if (!showingFrame) {
        this.viewFrame.cameraFrameUrl = "";
        this.$emit("show-loader", 2);
      }
    },
    markSpotBlocked() {
      if (this.markSpotBlocked) {
        this.spot.current_status = ParkingStatus.reserved;
      } else {
        this.spot.current_status = ParkingStatus.free;
      }
    },
    "specialArea.name"(newName) {
      if (!newName) {
        this.specialArea.is_show_area_name_enabled = false;
      }
    },
    "specialAreaData.current_vehicle_count_detected"(oldVal, newVal) {
      this.specialArea.current_vehicle_count_detected =
        this.specialAreaData.current_vehicle_count_detected;
    },
  },
});
